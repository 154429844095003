.main-navigation__menu-btn {
  width: 4rem;
  height: 3rem;
  background: var(--secundary-color);
  border: none;
  display: flex;
  flex-direction: column;
  /* border: 3px solid blue; */
  justify-content: space-around;
  align-items: center;
  margin-right: 1.5rem;
  cursor: pointer;
  padding: .6em;
  border-radius: .4em .4em .4em .4em;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  margin-left: .5em;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;

}

.main-navigation__drawer-nav {
  height: 100%;
}

.MainNavigation__main-social-media-container{
  width: 6em;
  margin-right: 0em;
  margin-left: 1em;
} 
  .MainNavigation__inner-social-media-container{ 
      display: flex;
      flex-direction: row;
      justify-content: space-around;
}


/* .MainNavigation__logo{
  background: #FFFFFF;
  height: 10em;
  width: 10em;
} */
.Navigation__logo{
  cursor: pointer;
}



/*================== FULL RESPONSIVE MODE ============*/

@media (min-width: 470px ) and (max-width: 575px){

  .main-navigation__menu-btn{
    /* border: 2px solid red ; */
  }
    .main-navigation__title{
      /* border: 8px solid green; */
      width: 7em; 
      margin-bottom: 0;   
    }
      .Navigation__logo{
        /* border: 4px solid purple; */
        width: 100%;
      }
    .MainNavigation__main-social-media-container{
      /* border: 3px solid blue; */
    }
  
}

@media (max-width: 768px ){

  .main-navigation__menu-btn{
    /* border: 2px solid red ; */
  }
    .main-navigation__title{
      /* border: 8px solid green; */
      width: 7em; 
      margin-bottom: 0;    
    }
      .Navigation__logo{
        /* border: 4px solid purple; */
        width: 100%;
      }
    .MainNavigation__main-social-media-container{
      /* border: 3px solid blue; */
    }
  
}



@media (min-width: 768px) and  (max-width: 991.98px){ 
  .main-navigation__title {
    color: white;
    margin-left: 1.1em;
    width: 7em; 
    margin-bottom: 0;   
  }
  .Navigation__logo{
    /* border: 4px solid purple; */
    width: 100%;
  } 
  .main-navigation__menu-btn {
    display: flex;
  }

  .main-navigation__header-nav {
    display: none;

  }

  .MainNavigation__main-social-media-container{
    width: 6em;
    margin-right: 2em;
  } 
    .MainNavigation__inner-social-media-container{ 
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}


@media (min-width: 992px) {
  .main-navigation__title {
    color: white;
    margin-left: 1.1em;
    width: 7em; 
    margin-bottom: 0;   
  }
  .Navigation__logo{
    /* border: 4px solid purple; */
    width: 100%;
  }
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }

  .main-navigation__title {
    color: white;
    margin-left:0;
    padding: 0;
  }

}


@media (min-width: 1200px) {
  .main-navigation__title{
    margin-left: .6em;
  }
  .MainNavigation__main-social-media-container{
    margin-right: 1.2em;
  }
  .Navigation__logo{ 
    height: 1.6em;
    width: 6.6em;
  }
}


@media (min-width: 1400px) { 

}
