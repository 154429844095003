.LoginForm__main-container{
    /* border: 3px solid red; */
    height: 85vh;
    margin-top: 6em;
    display: flex;
    align-items: center;
    justify-content: center;
}
    .LoginForm__inner-container{
        /* border: 3px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.5em;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
        .LoginForm__form-container{
            /* border: 3px solid green; */
            
        }
            .LoginForm__form-container h1{
                font-family: 'Lato', sans-serif;
                font-size: 3rem;
                font-weight: 700;
                text-align: center;
            }
            .LoginForm__form{
                margin-top: 2.5em;
            }
                .LoginForm__input-container{
                    /* border: 3px solid red; */
                }
                    .LoginForm__LabelForms{
                        margin-bottom: .5em;
                        margin-top: 1em;
                    }
                    .LoginForm__inputForms{
                        width: 100%;
                        padding: .5em;
                        border:  1px solid var(--primary-color);
                        border-radius: 3px;
                    }
                .LoginForm__main-button-container{
                    margin-top: 1em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 1em;
                }
                
                    .LoginForm__inner-button-container{

                    }
                        .LoginForm__submit-button{
                            border: none;
                            padding: 1em;
                            width: 9em;
                            background-color: var(--primary-color);
                            color: #fff;
                            border-radius: 3px;
                            font-family: 'Lato', sans-serif;
                            font-size: 1.1rem;
                        }

    
@media (max-width: 575.98px) {
    .LoginForm__main-container{
        padding: 1em;
    }

}
        
@media (min-width: 576px) and (max-width: 767.98px) {

}
        
        
@media (min-width: 768px) and (max-width: 991.98px) {

}
        
    
@media (min-width: 992px) and (max-width: 1199.98px) {

}
        
        
@media (min-width: 1200px) {
 
            
}