
.ProductDescription__main-container{
    /* border: 3px solid red; */
    display: flex;
    flex-direction: row;
    padding: 3em;
}
    .ProductDescription__explanation-container{
        /* border: 3px solid blue; */
        padding: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
        .ProductDescription__title-container{
            /* border: 3px solid green; */
            align-self: flex-start;
        }
            .ProductDescription__title-container h1{
                color: #00030A;
                font-family: Montserrat;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
                /* text-transform: uppercase; */
            }
        .ProductDescription__explanation-container p{
            color: #00030A;
            font-family: Lato;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem; 
        }
        
            

    .ProductDescription__explanation-container{
        /* border: 2px solid black; */
    }


    .ProductDescription__img-container{ 
        /* border: 7px solid green; */
        
        
    }
        .ProductDescription__img-inner-container{
            /* border: 2px solid blue; */
            height: 35em;
            width: 40em;
        }
            .ProductDescription__img{
                /* border: 2px solid purple; */
                width: 100%;
                height: 100%;
            }

.ProductDescription__list-container ol li{
    color: #00030A;
    font-family: Lato;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.25rem;
    margin-bottom: 1.5em;
}


.ProductDescription__explanation-container p {
    width: 100%;
}








@media (max-width: 575.98px) {
    .ProductDescription__main-container{
       /*  border: 3px solid red; */
        display: flex;
        flex-direction: column;
        padding: .5em;
        width: 100%;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    color: #00030A;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2.3rem; 
                    /* text-transform: uppercase; */
                }
                .ProductDescription__explanation-container p{
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 2.25rem; 
                }
                
            
                
    
        .ProductDescription__explanation-container{
            /* border: 2px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            padding: .6em;
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: auto;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .ProductDescription__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        padding: .5em;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    color: #00030A;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2rem; 
                    /* text-transform: uppercase; */
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: Lato;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; 
            }
            
                
    
        .ProductDescription__explanation-container{
            /* border: 2px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: auto;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
    
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ProductDescription__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        padding: .5em;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    color: #00030A;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2rem; 
                    /* text-transform: uppercase; */
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; 
            }
            
                
    
        .ProductDescription__explanation-container{
            /* border: 8px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: 20em;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ProductDescription__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        padding: .5em;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    color: #00030A;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2rem; 
                    /* text-transform: uppercase; */
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; 
            }
            
                
    
        .ProductDescription__explanation-container{
            /* border: 8px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: 20em;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
}


@media (min-width: 1200px)  {
    .ProductDescription__img-container{ 
        /* border: 7px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
          
    }
    .ProductDescription__title-container h1{
        color: #00030A; 
        font-family: Montserrat;
        font-size: 2.2rem;
        font-style: normal; 
        font-weight: 700;
        line-height: 2.1rem; 
        /* text-transform: uppercase; */
    }
    .ProductDescription__img-inner-container{
        /* border: 2px solid blue; */
        height: auto;
        width: 30em;
    }
        .ProductDescription__img{
            /* border: 2px solid purple; */
            width: 100%;
            height: 100%;
        }
}

