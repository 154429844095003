
.AssociatedBrand__main-section-container{
    /* border: 2px solid red; */
    height: auto;
}
.AssociatedBrand__main-title-container{
    /* border: 2px solid black; */
}
    .AssociatedBrand__main-title{
        /* border: 2px solid red; */
        color: #00030A;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem; 
    }

.AssociatedBrand__icons-contianer{
    /* border: 4px solid blue; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
    .AssociatedBrand__brand-one, .AssociatedBrand__brand-two, 
    .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
    .AssociatedBrand__brand-fives {
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

/*==================== RESPONSIVE =========================*/



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
   .AssociatedBrands__main-container{
    /* border: 3px solid red; */
   }
   .AssociatedBrand__main-title{
    padding-left: .5em;
    padding-right: .5em;
    font-size: 1.3rem;
}
    .AssociatedBrand__main-title-container{
        /* border: 2px solid blue; */
    }
    .AssociatedBrand__icons-contianer{
        /* border: 2px solid green; */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
    .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
    .AssociatedBrand__brand-five{
        /* border: 2px solid red; */
        width: 9em;
        height: 6em;
    }
        .AssociatedBrand__icon{
            width: 100%;
            height: auto;
        }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .AssociatedBrands__main-container{
        /* border: 8px solid rgb(0, 0, 0); */
       }
        .AssociatedBrand__main-title-container{
            /* border: 2px solid blue; */
        }
        .AssociatedBrand__icons-contianer{
            /* border: 2px solid green; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
        .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
        .AssociatedBrand__brand-five{
            /* border: 2px solid red; */
            width: 10em;
            height: 8em;
        }
            .AssociatedBrand__icon{
                width: 100%;
                height: auto;
            }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .AssociatedBrands__main-container{
        /* border: 8px solid rgb(0, 0, 0); */
       }
        .AssociatedBrand__main-title-container{
            /* border: 2px solid blue; */
        }
        .AssociatedBrand__icons-contianer{
            /* border: 2px solid green; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
        .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
        .AssociatedBrand__brand-five{
            /* border: 2px solid red; */
            width: 10em;
            height: 8em;
        }
            .AssociatedBrand__icon{
                width: 100%;
                height: auto;
            }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .AssociatedBrands__main-container{
        /* border: 8px solid rgb(0, 0, 0); */
       }
        .AssociatedBrand__main-title-container{
            /* border: 2px solid blue; */
        }
        .AssociatedBrand__icons-contianer{
            /* border: 2px solid green; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
        .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
        .AssociatedBrand__brand-five{
            /* border: 2px solid red; */
            width: 10em;
            height: 8em;
        }
            .AssociatedBrand__icon{
                width: 100%;
                height: auto;
            }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

 }

