.BenefitsList__main-container{
    /* border: 2px solid red; */
    padding: 2em 4em 2em 4em;
}
    .BenefitsList__list-container{
        /* border: 2px solid green; */
        display: flex;
        flex-direction: row;
    }
        .BenefitsList__first-benefits{
            /* border: 2px solid blue; */
            margin-left: 2.5em;
            margin-right: 2.5em;
        }

        .BenefitsList__second-benefits{
            /* border: 2px solid purple; */
            margin-left: 2.5em;
            margin-right: 2.5em;
        }

            .BenefitsList__item-container{
                /* border: 2px solid yellow; */
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
            }
                .BenefitsList__icon-container{
                    /* border: 2px solid purple; */
                    width: 8em;
                    margin-right: 1.5em;
                }
                    .BenefitsList__icon{
                        /* border: 2px solid green; */
                        width: 100%;
                    }
            
            .BenefitsList__description-container{
                /* border: 2px solid red; */
            }   
                .BenefitsList__title{
                    /* border: 2px solid blue; */
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.25rem; /* 150% */
                }
                .BenefitsList__description{
                    /* border: 2px solid red; */
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 2.25rem;
                    
                }


               
@media (max-width: 575.98px) {
    .BenefitsList__main-container{
        /* border: 9px solid red; */
        padding: 1em;
    }
        .BenefitsList__list-container{
            /* border: 8px solid green; */
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }
            .BenefitsList__first-benefits{
                /* border: 8px solid blue; */
                margin:0;
                padding: 0;
            }
    
            .BenefitsList__second-benefits{
                /* border: 2px solid purple; */
                margin: 0;
                padding: 0;
            }
    
                .BenefitsList__item-container{
                    /* border: 2px solid yellow; */
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                }
                    .BenefitsList__icon-container{
                        /* border: 2px solid purple; */
                        width: 4em;
                        margin-right: 1.5em;
                    }
                        .BenefitsList__icon{
                            /* border: 2px solid green; */
                            width: 100%;
                        }
                
                .BenefitsList__description-container{
                    /* border: 2px solid red; */
                    width: 100%;
                }   
                    .BenefitsList__title{
                        /* border: 2px solid blue; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.3rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.7rem; /* 150% */
                    }
                    .BenefitsList__description{
                        /* border: 2px solid red; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.1rem;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.6rem;
                    }
    
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .BenefitsList__main-container{
        /* border: 9px solid red; */
        padding: 1.5em;
    }
        .BenefitsList__list-container{
            /* border: 8px solid green; */
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }
            .BenefitsList__first-benefits{
                /* border: 8px solid blue; */
                margin:0;
                padding: 0;
            }
    
            .BenefitsList__second-benefits{
                /* border: 2px solid purple; */
                margin: 0;
                padding: 0;
            }
    
                .BenefitsList__item-container{
                    /* border: 2px solid yellow; */
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                }
                    .BenefitsList__icon-container{
                        /* border: 2px solid purple; */
                        width: 5em;
                        margin-right: 1.5em;
                    }
                        .BenefitsList__icon{
                            /* border: 2px solid green; */
                            width: 100%;
                        }
                
                .BenefitsList__description-container{
                    /* border: 2px solid red; */
                    width: 100%;
                }   
                    .BenefitsList__title{
                        /* border: 2px solid blue; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.3rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.7rem; /* 150% */
                    }
                    .BenefitsList__description{
                        /* border: 2px solid red; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.1rem;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.6rem;
                    }
}

               
@media (min-width: 768px) and (max-width: 991.98px) {
    .BenefitsList__main-container{
        /* border: 9px solid red; */
        padding: 2em;
    }
        .BenefitsList__list-container{
            /* border: 8px solid green; */
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
        }
            .BenefitsList__first-benefits{
                /* border: 8px solid blue; */
                margin:0;
                padding: 0;
            }
    
            .BenefitsList__second-benefits{
                /* border: 2px solid purple; */
                margin: 0;
                padding: 0;
            }
    
                .BenefitsList__item-container{
                    /* border: 2px solid yellow; */
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    margin-left: .6em;
                    margin-right: .6em;
                }
                    .BenefitsList__icon-container{
                        /* border: 2px solid purple; */
                        width: 6em;
                        margin-right: 1.5em;
                    }
                        .BenefitsList__icon{
                            /* border: 2px solid green; */
                            width: 100%;
                        }
                
                .BenefitsList__description-container{
                    /* border: 2px solid red; */
                    width: 100%;
                }   
                    .BenefitsList__title{
                        /* border: 2px solid blue; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.4rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.7rem; /* 150% */
                    }
                    .BenefitsList__description{
                        /* border: 2px solid red; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.3rem;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.6rem;
                    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .BenefitsList__main-container{
        /* border: 9px solid red; */
        padding: 2.2em;
    }
        .BenefitsList__list-container{
            /* border: 8px solid green; */
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
        }
            .BenefitsList__first-benefits{
                /* border: 8px solid blue; */
                margin:0;
                padding: 0;
            }
    
            .BenefitsList__second-benefits{
                /* border: 2px solid purple; */
                margin: 0;
                padding: 0;
            }
    
                .BenefitsList__item-container{
                    /* border: 2px solid yellow; */
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    margin-left: .6em;
                    margin-right: .6em;
                }
                    .BenefitsList__icon-container{
                        /* border: 2px solid purple; */
                        width: 6em;
                        margin-right: 1.5em;
                    }
                        .BenefitsList__icon{
                            /* border: 2px solid green; */
                            width: 100%;
                        }
                
                .BenefitsList__description-container{
                    /* border: 2px solid red; */
                    width: 100%;
                }   
                    .BenefitsList__title{
                        /* border: 2px solid blue; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.4rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.7rem; /* 150% */
                    }
                    .BenefitsList__description{
                        /* border: 2px solid red; */
                        color: #00030A;
                        font-family: Lato;
                        font-size: 1.3rem;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.6rem;
                    }
}

             
@media (min-width: 1200px) {
    .BenefitsList__main-container{
        margin-top: 2em;
    }
    .BenefitsList__first-benefits{
        margin-right: 3em;
        margin-left: 3em;
    }
    .BenefitsList__second-benefits{
        margin-right: 3em;
        margin-left: 2.5em;
    }
    .BenefitsList__icon-container{
        /* border: 2px solid purple; */
        width: 10em;
        margin-right: 1.5em;
    }
        .BenefitsList__icon{
            /* border: 2px solid green; */
            width: 100%;
        }

.BenefitsList__description-container{
    /* border: 2px solid red; */
    width: 100%;
}  
.BenefitsList__icon-container{
    /* border: 2px solid purple; */
    width: 6em;
    margin-right: 1.5em;
}
    .BenefitsList__icon{
        /* border: 2px solid green; */
        width: 100%;
    }
}