
.SectionDivider__section-container{
    /* border: 2px solid red; */
    background-color: var(--secundary-color);
    padding: .5em;
}
    .SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
    }
        .SectionDivider__title-container{
            /* border: 3px solid blue; */
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;

        }   
            .SectionDivider__title{
                /* border: 3px solid red; */
                color: #FFF;
                text-align: center;
                font-family: Montserrat;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
            }
        .SectionDivider__icons-container{
                /* border: 3px solid green; */
                display: flex;
        }
            .SectionDivider__inner-icons-container p {
                color: var(--tertiary-color);
                text-align: center;
                font-family: Montserrat;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-top: 1em;
                            }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid purple; */
                display: flex;
                
            }
                .SectionDivider__icon-container{
                    /* border: 3px solid green; */
                    width: 4em;
                    margin-right: 1.5em;
                }
                    .SectionDivider__icon{
                        /* border: 3px solid red; */
                        width: 100%;
                    }

@media (max-width: 575.98px) {

    .SectionDivider__inner-icons-container{
        /* border: 3px solid red; */
    }
        .SectionDivider__icon-container{
            /* border: 3px solid white; */
        }
    .SectionDivider__text-container{
        /* border: 3px solid green; */
        width: 100%;
    }
    .SectionDivider__text-container p {
        color: #FFF;
    }

   .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
       
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: column;
        }
        .SectionDivider__icon-container{
            /* border: 3px solid white; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        /*To improve mobile version*/
        .SectionDivider__icon-container{
            /* border: 3px solid white; */
            min-width: 16%;
            max-width: 15%;
        }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 100%;
            }
                .SectionDivider__icon{
                    /* border: 3px solid green; */
                    width: 100%;
                }
                .SectionDivider__text-container p{
                    font-size: 100%;
                } 
        .SectionDivider__inner-icons-container{
            margin-top: .5em;
        }  
}

@media (min-width: 440px){
    .SectionDivider__text-container p{
        font-size: 1.3rem;
    } 
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;

      
   }.SectionDivider__inner-container{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 1.5em;
                
            }
            /*test*/
            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }

            /*end test*/
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;  
                color: #FFF;
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
                margin-top: 2em;
            }

            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }
            .SectionDivider__text-container p{
                color: #FFF;
            }

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        align-items: center;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
                margin-left: .5em;
                margin-right: .5em;
            }
                .SectionDivider__icon-container{
                    margin: 0;
                }
                  
           

            .SectionDivider__text-container p{
                color: #FFF;
            }
}


@media (min-width: 1200px) {
    .SectionDivider__section-container{
        padding: 5em 4em 5em 4em;
        /* border: 3px solid red; */
        margin: 0;
    }
    .SectionDivider__title-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding: 0;
        margin-bottom: 2.6em;
    }
    .SectionDivider__inner-container{
        display: flex;
        flex-direction: column; 

    }
    .SectionDivider__icons-container{
        flex-direction: row;
        justify-content: space-around;

    }
    .SectionDivider__inner-icons-container{
        flex-direction:column;
        justify-content: center;
        align-items: center;
        margin-left: 1em;
        margin-right: 1em;
    }
    .SectionDivider__icon-container{
        margin: 0;
    }

    .SectionDivider__text-container p{
        color: #FFF;
    }
}