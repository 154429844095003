.Cancelation__contact-form-container{
    background-color: #E6E5E5;
    border-radius: 1.25rem;
} 

@media (max-width: 575.98px) {
    .Cancelation__contact-information-container{
        padding: 1.5em;
    }
    .Cancelation__contact-form-container{
        padding: 1em;
        margin: .5em;
        /* border: 3px solid red; */
    }
    
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Cancelation__contact-information-container{
        padding: 2em;
    }
    .Cancelation__contact-form-container{
        margin: 2em;
    }
        .Cancelation__contact-form-container{
            border-radius: 1.25rem;
            margin: 1.5em;
            padding: 2em;
        }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Cancelation__contact-information-container{
        padding: 2em;
    }
    .Cancelation__contact-form-container{
        margin-left: 10em;
        margin-right: 10em;
        margin-bottom: 2em;
        padding: 2em;
        
    }   
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Cancelation__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 10em;
    }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
            margin-left: 6em;
            margin-right: 3em;
        }
        .Cancelation__contact-form-container{
            width: 35em;
            height: 100%;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 1.5em 1.5em 1.5em 1.5em;
            background-color: #E6E5E5;
            margin-left: 2em;
            margin-right: 3em;
        }
}

@media (min-width: 1200px) {
    .Cancelation__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 10em;
    }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
        }
        .Cancelation__contact-form-container{
            width: 35em;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 2.5em;
            background-color: #E6E5E5;
            margin-right: 1.5em;
        }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
            margin-left: 4em;
            margin-right: 3em;
        }

}