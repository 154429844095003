

.OfferCard__main-card-container {
    display: inline-flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    margin-left: .6em;
    margin-right: .6em;
    gap: 1.875rem;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 25em;
    width: 25em;
}

    .OfferCard__inner-card-container{
        width: 100%;
        height: 15em;
        position: relative;
    }
        
        .OfferCard__img{
            width: 100%;
            height: auto;
        }
    
        .OfferCard__offer-information{
                /* border: 7px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position:absolute;
            top: 0px;
            width: 100%;
            gap: 1.25rem;
            height: 3.3em;
        }
            .OfferCard__offer{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                /* border: 3px solid black; */
                width: 40%;
                background-color: var(--primary-color);
                border-radius: 0.3125rem;
                padding: 0;
            
            }
                .OfferCard__offer-value{
                    /* border: 3px solid green; */
                }
                    .OfferCard__offer-value p{
                        color: #FFF;
                        text-align: right;
                        font-size: 1.1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 2.25rem; 
                        padding-top: 1em;
                    }

                .OfferCard__offer-icon{
                    /* border: 3px solid blue; */
                    width: 3em;
                    height: 2.5em;
                    display: flex;
                    align-items: center;
                }
                    .OfferCard__icon{
                        width: 100%;
                        height: 2em;
                    }

        .OfferCard__trial-container{
            /* border: 2px solid red; */
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .OfferCard__trial-container p{
                color: #000;
                text-align: center;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                /* border: 3px solid red; */
                margin: .5em;
            }

        .OfferCard__button-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
            .OfferCard__button{
                /* border: 6px solid yellow; */
                width: 50%;

               
            }
                .OfferCard__button button{
                    width: 100%;
                    background-color: rgba(255, 255, 255, 1);
                    border: 1px solid var(--quaternary-color);
                    border-radius: 1.25rem;
                    padding: .5rem  1rem;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 1.1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.5rem; /* 100% */
                    text-transform: uppercase;
                }
                .OfferCard__button button:hover{
                    width: 100%;
                    background-color: var(--quaternary-color);
                    color: #ffffff;
                    border-radius: 1.25rem;
                    padding: .5rem 1rem;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 1.1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.5rem; /* 100% */
                    text-transform: uppercase;
                }



@media (max-width: 575.98px) {
    .OfferCard__main-card-container {
        display: inline-flex;
        padding-bottom: 0px;
        flex-direction: column;
        align-items: center;
        margin-left: .6em;
        margin-right: .6em;
        gap: 1.875rem;
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: auto;
        width: auto;
        /* border: 2px solid green; */
    }
    
        .OfferCard__inner-card-container{
            width: 100%;
            height: auto;
            position: relative;
            
        }
            
            .OfferCard__img{
                width: 100%;
                height: auto;
            }
        
            .OfferCard__offer-information{
                    /* border: 7px solid green; */
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position:absolute;
                top: 0px;
                width: 100%;
                gap: 1.25rem;
                height: 3.3em;
                
            }
                .OfferCard__offer{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    /* border: 3px solid black; */
                    max-width: 60%;
                    min-width: 7em;
                    background-color: var(--primary-color);
                    border-radius: 0.3125rem;
                    padding: 0;
                
                }
                    .OfferCard__offer-value{
                      /*   border: 3px solid green; */
                        margin-left: 3%;
                        
                    }
                        .OfferCard__offer-value p{
                            color: #FFF;
                            text-align: right;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2.25rem; 
                            padding-top: 1em;
                        }
    
                    .OfferCard__offer-icon{
                        /* border: 3px solid blue; */
                        width: 2.3em;
                        height: 2.1em;
                        display: flex;
                        align-items: center;
                    }
                        .OfferCard__icon{
                            width: 100%;
                            height: 2em;
                            /* height: 2em; */
                        }
    
            .OfferCard__trial-container{
                /* border: 2px solid red; */
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
                .OfferCard__trial-container p{
                    color: #000;
                    text-align: center;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    /* border: 3px solid red; */
                    margin: .5em;
                }
    
            .OfferCard__button-container{
                /* border: 3px solid green; */
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: .6em;
            }
                .OfferCard__button{
                    /* border: 6px solid yellow; */
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
    
                   
                }
                    /* .OfferCard__button button{
                        width: 100%;
                        font-size: 1.1rem;
                        font-weight: 600;
                        line-height: 1.5rem; 
                        padding-left: 2em;
                        padding-right: 2em;
                      
                    } */
                    /* .OfferCard__button button:hover{
                        width: 100%;
                        background-color: #003C86;
                        color: #ffffff;
                        border-radius: 1.25rem;
                        padding: .5rem  0.3125rem;
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 1.1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.5rem; 
                        text-transform: uppercase;
                    } */        
}
                
                
 @media (min-width: 576px) and (max-width: 767.98px) { 
    .OfferCard__offer-icon{
        /* border: 3px solid green; */
        width: 2.2em;
    }
        .OfferCard__icon{
            /* border: 3px solid red; */
            width: 100%;
        }
}
 
 
 @media (min-width: 768px) and (max-width: 991.98px) {
    .OfferCard__offer-icon{
        /* border: 3px solid green; */
        width: 2.2em;
    }
        .OfferCard__icon{
            /* border: 3px solid red; */
            width: 100%;
        }
}

 @media (min-width: 992px) and (max-width: 1199.98px) {
    .OfferCard__offer-icon{
        /* border: 3px solid green; */
        width: 2.2em;
    }
        .OfferCard__icon{
            /* border: 3px solid red; */
            width: 100%;
        }
 
}
 

 @media (min-width: 1200px) {
    .OfferCard__offer-icon{
        /* border: 3px solid green; */
        width: 2.2em;
    }
        .OfferCard__icon{
            /* border: 3px solid red; */
            width: 100%;
        }
}
    




        

        
            


       



