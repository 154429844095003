
.SubscriptionPlan__main-section-container{

}
    .SubscriptionPlan__title-container{

    }
        .SubscriptionPlan__title{
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem; 
            margin-top: 2em;
            margin-bottom: 1.5em;
        }
    .SubscriptionPlan__subscription-plans-container{
        display: flex;
    }

    .SubscriptionPlan__customer-service-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2.5em;
        margin-bottom: 1.5em;
    }







@media (max-width: 575.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 3rem; 
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 2.8rem; 
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionPlan__main-section-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 0;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 0;
    }
}

@media (min-width: 1200px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row ;
        justify-content: center;
        align-content: center;
    }
}