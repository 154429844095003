
.WelcomeSection__main-section-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 103vh;
    /* border: .1px solid white; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 6em;
    /* margin-top: 6em; */
    /* border: 3px solid red; */
}




/*============== CARD DESIGN ============*/

.WelcomeSection__main-card-info-container {
    display: inline-flex;
    padding: 1.875rem 3.125rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.625rem;
    /* border: 3px solid red; */
    border-radius: 0.625rem;
    /* background: rgba(255, 255, 255, 0.50); */
}

    .WelcomeSection__main-title-container {
        display: flex;
        width: 33.625rem;
        height: 12.1875rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
    }
        .WelcomeSection__main-title-container h1 {
            color: var(--quaternary-color);
            font-family: 'Bungee', sans-serif;
            font-size: 3rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }


    .WelcomeSection__main-body-container{
        display: flex;
        width: 33.625rem;
        height: 10.8125rem;
        justify-content: flex-start;
        align-items: center;
        /* border: 3px solid green; */
        flex-shrink: 0;
    }
        .WelcomeSection__main-body-container p {
            color: #00030A;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem; 
        }
            .WelcomeSection__main-body-container p span {
                color: #00030A;
                font-family: 'Lato',sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: 2.25rem;
            }

    .WelcomeSection__main-button-container{
          
    }
        .WelcomeSection__inner-button-container{
            display: flex;
            padding: 1.25rem 0.625rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            border-radius: 5px;
            border: none;
            background:  var(--quaternary-color);
            color: #FFF;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; 
            text-transform: uppercase;
            text-decoration: none;
        }

        .WelcomeSection__inner-button-container:hover {
            color: white;
        }  

        .CardInfo__important-text{
            color: #000000;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
        }
    .WelcomeSection__link{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 100% */
        text-transform: uppercase;
        text-decoration: none;
        /* border: 3px solid green; */
        display: inline-block;
    }
    .WelcomeSection__link:hover{
        color: #FFF;
    }

/*==================== RESPONSIVE =========================*/


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
.WelcomeSection__main-section-container-without-bg{
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}.WelcomeSection__inner-section-container-without-bg{
    padding: 1em;
    width: 100%;
    /* border: 3px solid green; */
}.WelcomeSection__inner-section-container-without-bg h1{
    /* color: #C2261C; */
    font-family: 'Bungee', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 3.3rem;
    /* border: 3px solid blue; */
    text-align: center;
    word-break: break-word;
}





    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120vh;
        margin-top: 6em;
        padding: 0;
        width: 100%;
    } 
        .WelcomeSection__inner-section-container{
            /* border: 6px solid green; */
            height: 100%;
            width: 90%;
            background-size: 100% 100%; 
            background-position: center;
            background-repeat: no-repeat;
            order: 2;
        }
        .WelcomeSection__card-info-component-container{
            /* border: 6px solid red; */
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em;
            order: 1;
        }
            .WelcomeSection__main-card-info-container{
                /* border: 3px solid black; */
                height: 100%;
                width: 100%;
                /* max-width: 19em; */
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
                .WelcomeSection__main-title-container{
                    /* border: 3px solid green; */
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                    .WelcomeSection__main-title-container h1{
                        box-shadow: none;
                        line-height: 3.3rem;
                    }
                .WelcomeSection__main-body-container{
                    /* border: 3px solid blue; */
                    width: 100%;
                    height: auto;
                    margin-top: 1.5em;
                    
                    
                }
                    .WelcomeSection__main-body-container p{
                        font-family: 'Lato', sans-serif;
                        font-size: 1.25rem;
                        line-height: 1.6rem;
                        margin: 0;
                    }   
                        .CardInfo__important-text{
                            font-family: 'Lato', sans-serif;
                            font-size: 1.25rem !important;
                            line-height: 1.6rem !important;
                      
                        }
                    .WelcomeSection__main-button-container{
                        /* border: 3px solid blue; */
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 1em;
                        
                    }
                        .WelcomeSection__inner-button-container{
                            /* border: 3px solid red; */
                            width: 100%;
                            height: 100%;
                            max-height: 3.1em;
                            max-width: 12em;
                        }
                            .WelcomeSection__link{
                                /* border: 3px solid black; */
                                width: 100%;
                                height: 100%;
                                max-height: 3.1em;
                                max-width: 12em;
                            }


    .text1{
        border: 3px solid blue;
    position: absolute;
    }

 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .WelcomeSection__main-section-container-without-bg{
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
    }.WelcomeSection__inner-section-container-without-bg{
        padding: 1em;
        width: 100%;
        /* border: 3px solid green; */
    }.WelcomeSection__inner-section-container-without-bg h1{
        font-family: 'Bungee', sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3rem;
        text-align: center;
    }
   
    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150vh;
        margin-top: 6em;
        padding: 0;
        width: 100%;
    } 
        .WelcomeSection__inner-section-container{
            /* border: 3px solid green; */
            height: 50%;
            width: 100%;
            background-size: 100% 100%; 
            background-position: center;
            background-repeat: no-repeat;
            order: 2;
        }
        .WelcomeSection__card-info-component-container{
            /* border: 3px solid red; */
            height: 50%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            order: 1;
        }
            .WelcomeSection__main-card-info-container{
                /* border: 6px solid black; */
                padding-left: 2em;
                height: 100%;
                width: 100%;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
                .WelcomeSection__main-title-container{
                    /* border: 3px solid green; */
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                }
                    .WelcomeSection__main-title-container h1{
                        box-shadow: none;
                        line-height: 3.5rem;
                    }
                .WelcomeSection__main-body-container{
                    /* border: 3px solid blue; */
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    margin-top: 1em;
                    
                    
                }
                    .WelcomeSection__main-body-container p{
                        font-family: 'Lato', sans-serif;
                        font-size: 1.25rem;
                        line-height: 1.6rem;
                        margin: 0;
                        /* border: 3px solid red; */
                        width: 90%;
                    }   
                        .CardInfo__important-text{
                            font-family: 'Lato', sans-serif;
                            font-size: 1.25rem !important;
                            line-height: 1.6rem !important;
                      
                        }
                    .WelcomeSection__main-button-container{
                        /* border: 3px solid blue; */
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 1em;
                        
                    }
                        .WelcomeSection__inner-button-container{
                            /* border: 3px solid red; */
                            width: 100%;
                            height: 100%;
                            max-height: 3.1em;
                            max-width: 12em;
                        }
                            .WelcomeSection__link{
                                /* border: 3px solid black; */
                                width: 100%;
                                height: 100%;
                                max-height: 3.1em;
                                max-width: 12em;
                            
                            }

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .WelcomeSection__main-section-container-without-bg{
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
    }.WelcomeSection__inner-section-container-without-bg{
        padding: 1em;
        width: 100%;
        /* border: 3px solid green; */
    }.WelcomeSection__inner-section-container-without-bg h1{
        font-family: 'Bungee', sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;
    }
    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 155vh;
        margin-top: 6em;
        padding: 0;
        width: 100%;
    } 
        .WelcomeSection__inner-section-container{
            /* border: 3px solid green; */
            height: 50%;
            width: 80%;
            background-size: 100% 100%; 
            background-position: center;
            background-repeat: no-repeat;
            order: 2;
        }
        .WelcomeSection__card-info-component-container{
            /* border: 3px solid red; */
            height: 50%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em;
            order: 1;
        }
            .WelcomeSection__main-card-info-container{
                /* border: 3px solid black; */
                padding-left: 3em;
                height: 100%;
                width: 100%;
                /* max-width: 19em; */
                /* padding: 0; */
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
                .WelcomeSection__main-title-container{
                    /* border: 3px solid green; */
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    margin-top: 1em;
                }
                    .WelcomeSection__main-title-container h1{
                        /* border: 3px solid blue; */
                        margin-bottom: 0;
                        box-shadow: none;
                        line-height: 3.5rem;

                    }
                .WelcomeSection__main-body-container{
                    /* border: 3px solid blue; */
                    width: 100%;
                    height: auto;
                    margin-top: 1.3em;
                    
                    
                }
                    .WelcomeSection__main-body-container p{
                        font-family: 'Lato', sans-serif;
                        font-size: 1.25rem;
                        line-height: 1.6rem;
                        margin: 0;
                        /* border: 3px solid blue; */
                        width: 90%;
                    }   
                        .CardInfo__important-text{
                            font-size: 1.25rem !important;
                            line-height: 1.6rem !important;
                      
                        }
                    .WelcomeSection__main-button-container{
                        /* border: 3px solid blue; */
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 1.5em;
                        
                    }
                        .WelcomeSection__inner-button-container{
                            /* border: 3px solid red; */
                            width: 100%;
                            height: 100%;
                            max-height: 3.1em;
                            max-width: 12em;
                        }
                            .WelcomeSection__link{
                                /* border: 3px solid black; */
                                width: 100%;
                                height: 100%;
                                max-height: 3.1em;
                                max-width: 12em;
                            
                            }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

    .WelcomeSection__main-section-container-without-bg{
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
    }.WelcomeSection__inner-section-container-without-bg{
        padding: 1em;
        width: 100%;
        /* border: 3px solid green; */
    }.WelcomeSection__inner-section-container-without-bg h1{
        font-family: 'Bungee', sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;
    }
    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        margin-top: 6em;
        padding: 0;
        width: 100%;
        position: relative;
    } 

        .WelcomeSection__inner-section-container{
            height: 100%;
            /* height: auto; */
            width: 100%;
            background-size: 100% 100%; 
            background-position: center;
            background-repeat: no-repeat;
            /* border: 3px solid red; */
            order: 2;
        }
        .WelcomeSection__card-info-component-container{
            height: 33em;
            /* max-height: 33em; */
            width: 100%;
            max-width: 50em;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em;
            /* border: 3px solid rgb(0, 0, 0); */
            /* position: absolute; */
            order: 1;
        }
            .WelcomeSection__main-card-info-container{
                /* border: 3px solid blue; */
                height: 100%;
                width: 100%;
                padding: 1em;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }
                .WelcomeSection__main-title-container{
                    /* border: 3px solid blue; */
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                    .WelcomeSection__main-title-container h1{
                        /* border: 3px solid purple; */
                        width: 100%;
                        font-size: 2.8rem;
                        font-weight: 700;
                        box-shadow: none;
                        line-height: 3.5rem;
                    }
                .WelcomeSection__main-body-container{
                    /* border: 3px solid green; */
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    
                }
                    .WelcomeSection__main-body-container p{
                        font-family: 'Lato', sans-serif;
                        font-size: 1.5rem;
                        line-height: 2.3rem;
                        font-weight: 300;
                        margin: 0;
                        /* border: 3px solid red; */
                        width: 90%;
                    }   
                        .CardInfo__important-text{
                            font-family: 'Lato', sans-serif;
                            font-size: 1.5rem !important;
                            line-height: 1.6rem !important;
                      
                        }
                    .WelcomeSection__main-button-container{
                        width: 90%;
                    }
                        .WelcomeSection__inner-button-container{
                            /* border: 3px solid red; */
                            width: 100%;
                            height: 100%;
                            max-height: 3.3em;
                            max-width: 15em;
                            border-radius: 10px;
                        }
                            .WelcomeSection__link{
                                /* border: 3px solid black; */
                                width: 100%;
                                height: 100%;
                                max-height: 3.1em;
                                max-width: 12em;
                            
                            }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .WelcomeSection__main-section-container-without-bg{
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
    }.WelcomeSection__inner-section-container-without-bg{
        padding: 1em;
        width: 100%;
        /* border: 3px solid green; */
    }.WelcomeSection__inner-section-container-without-bg h1{
        font-family: 'Bungee', sans-serif;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;
    }
    .WelcomeSection__main-section-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 110vh;
        margin-top: 6em;
        padding: 0;
        width: 100%;
        position: relative;
    } 

        .WelcomeSection__inner-section-container{
            height: 100%;
            /* height: auto; */
            width: 100%;
            background-size: 100% 100%; 
            background-position: center;
            background-repeat: no-repeat;
            /* border: 3px solid blue; */
            order: 2;
        }
        .WelcomeSection__card-info-component-container{
            height: 33em;
            /* max-height: 33em; */
            width: 100%;
            max-width: 50em;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em;
            order: 1;
        }
            .WelcomeSection__main-card-info-container{
                /* border: 3px solid blue; */
                height: 100%;
                width: 100%;
                padding: 1em;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }
                .WelcomeSection__main-title-container{
                    /* border: 3px solid blue; */
                    width: 80%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                    .WelcomeSection__main-title-container h1{
                        font-size: 3rem;
                        font-weight: 700;
                        box-shadow: none;
                        width: 100%;
                        line-height: 3.9rem;
                    }
                .WelcomeSection__main-body-container{
                    /* border: 3px solid green; */
                    width: 80%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    
                }
                    .WelcomeSection__main-body-container p{
                        font-family: 'Lato', sans-serif;
                        font-size: 1.5rem;
                        line-height: 2.6rem;
                        font-weight: 300;
                        margin: 0;
                        /* border: 3px solid red; */
                        width: 90%;
                    }   
                        .CardInfo__important-text{
                            font-family: 'Lato', sans-serif;
                            font-size: 1.5rem !important;
                            line-height: 1.6rem !important;
                      
                        }
                    .WelcomeSection__main-button-container{
                        width: 80%;
                    }
                        .WelcomeSection__inner-button-container{
                            /* border: 3px solid red; */
                            width: 80%;
                            height: 100%;
                            max-height: 3.3em;
                            max-width: 15em;
                            border-radius: 10px;
                        }
                            .WelcomeSection__link{
                                /* border: 3px solid black; */
                                width: 100%;
                                height: 100%;
                                max-height: 3.1em;
                                max-width: 12em;
                            }
 }